/*Home Section*/
.map {
  height: auto;
  width: 70vw;
  margin-left: -20vw;
  margin-top: -10vh;
}

.about-img {
  height: auto;
  width: 40vw;
  margin-left: -4vw;
}

@media only screen and (max-width: 600px) {
  .about-img {
    height: 25vh;
    width: 80vw;
  }
}

.home_h1 { 
  font-weight: 380;
  color: #fff;
  line-height: 1.5em;
  font-size: 1.7em;
  margin-top: 20vh;
  margin-left: -2vw;
}

.home_h1_span {
  font-weight: 700;
  font-size: 3.2rem;
}

.home_h2_span {
  font-size: 1.5rem;
}

.date {
  margin: 5vh auto 0 auto;
  width: 45vw;
  height: auto;
  padding: 5%;
  margin-left: -2vw;
}

@media screen and (max-width: 575px) {
  .home_h1 {
    font-size: 1.5rem;
  }
  .home_h1_span {
    font-size: 1.8rem;
  }
  .home_h2_span {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 767px) {
  .map {
    overflow: hidden;
    width: 140vw;
    margin-left: -27vw;
  }
  .home_h1 {
    margin: -10vh auto 0 auto;
  }
  .date {
    width: 80vw;
  }
}

/*About Section*/
.about_h2 {
  font-weight: 900;
  text-align: left;
  color: #fff;
  line-height: 2em;
  font-size: 1.4rem;
}

.about_h1 {
  font-weight: 500;
  color: #fff;
  font-size: 5rem;
  line-height: 0.8em;
  letter-spacing: -0.07em;
}

.about_heading {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.track_bg_img {
  background-image: url("logo.png");
  background-repeat: no-repeat;
}

@media screen and (max-width: 576px) {
  .about_h2 {
    font-size: 1.2rem;
  }
  .about_h1 {
    font-size: 3rem;
  }
}

/*Tracks Section*/
.tracks_card {
  background: #242526;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
  padding: 1em;
  border-radius: 10px;
  max-width: 17rem;
  margin: 10px auto;
  height: 10rem;
}

.tracks_h1 {
  font-weight: 900;
  color: #fff;
  font-size: 5rem;
}

.tracks_heading {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tracks_h4 {
  font-weight: 600;
  text-align: left;
  margin-top: 1em;
  font-size: 1.3rem;
  color: #fff;
}

.tracks_icon {
  width: 2.5em;
}

.card_icon {
  height: 2.5em;
  width: 2.5em;
  border-radius: 50%;
}

@media screen and (max-width: 576px) {
  .tracks_h1 {
    font-size: 3rem;
  }
}

/*Sponsors Section*/
.sponsors_h1 {
  padding-top: 5%;
  font-weight: 800;
  color: #fff;
  font-size: 4rem;
  line-height: 0.8em;
  letter-spacing: -0.07em;
}
.sponsors_h3 {
  font-weight: 800;
  color: #fff;
  font-size: 3rem;
  line-height: 0.8em;
  letter-spacing: -0.07em;
}

.logo-large {
  width: 100%;
  max-width: 450px;
  height: auto;
  margin: 0 auto;
  padding: 10px 10px;
}

.logo-mid {
  width: 100%;
  max-width: 300px;
  height: auto;
  margin: 0 auto;
  padding: 10px 10px;
}

.logo-script {
  width: 100%;
  max-width: 400px;
  height: auto;
  margin: 0 auto;
}

.logo-webwiz {
  width: 100%;
  max-width: 295px;
  height: auto;
  margin: 0 auto;
}

.logo-rourkela360 {
  width: 100%;
  max-width: 260px;
  height: auto;
  margin: 0 auto;
}

.logo_small {
  max-width: 290px;
  max-height: 150px;
  margin: 0 auto;
  padding: 20px;
}

@media screen and (max-width: 576px) {
  .sponsors_h1 {
    font-size: 4rem;
  }
}

/*Schedule Section*/
.schedule_container {
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.schedule_h1 {
  font-weight: 800;
  padding-top: 5%;
  color: #fff;
  font-size: 4rem;
  line-height: 0.8em;
  letter-spacing: -0.07em;
}

.schedule_h2 {
  text-align: center;
  color: #fff;
  font-size: 2.5rem;
}

@media screen and (max-width: 576px) {
  .schedule_h1 {
    font-size: 3.2rem;
  }
  .schedule_h2 {
    font-size: 2rem;
  }
}

/*Events Section*/
.events_container {
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.events_h1 {
  font-weight: 800;
  padding-top: 5%;
  color: #fff;
  font-size: 4rem;
  line-height: 0.8em;
  letter-spacing: -0.07em;
}

.events_h2 {
  text-align: center;
  color: #fff;
  font-size: 2.5rem;
}

@media screen and (max-width: 576px) {
  .events_h1 {
    font-size: 4rem;
  }
  .events_h2 {
    font-size: 2rem;
  }
}

/*Prizes Section*/
.prizes_container {
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.prizes_h1 {
  font-weight: 800;
  padding-top: 5%;
  color: #fff;
  font-size: 4rem;
  line-height: 0.8em;
  letter-spacing: -0.07em;
}

.prizes_h2 {
  text-align: center;
  color: #fff;
  font-size: 2.5rem;
}

@media screen and (max-width: 576px) {
  .prizes_h1 {
    font-size: 4rem;
  }
  .prizes_h2 {
    font-size: 2rem;
  }
}

/*FAQ Section*/
.faq_heading {
  padding-top: 5%;
  font-weight: 800;
  color: #fff;
  font-size: 4rem;
  line-height: 0.8em;
  letter-spacing: -0.07em;
}

.faq_content {
  padding: auto;
  padding-bottom: 4%;
  text-align: left;
}

@media screen and (max-width: 576px) {
  .faq_heading {
    font-size: 4rem;
  }
}

.contact_h2 {
  text-align: center;
  color: #fff;
  font-size: 1.5rem;
  text-decoration: none;
}

.contact_h2 a {
  text-align: center;
  color: #fff;
  font-size: 1.5rem;
  text-decoration: none;
  color: #b643e6;
}
